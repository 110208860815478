<template>
  <div>
    <Pane />

    <div class="container">
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" :colon="false" @submit="handleSubmit" :form="form">
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="分类">
              <a-select style="width: 100%" v-decorator="[
                'pid',
                { rules: [{ required: true, message: '请选择！' }] },
              ]">
                <a-select-option v-for="(item) in categoryList" :key="item.pid" :value="item.pid">{{ item.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>


          <a-col :lg="12" :md="12" :sm="24" v-if="form.getFieldValue('pid') === '17'">
            <a-form-item label="专业类型">
              <a-select style="width: 100%" v-decorator="['major',
                { rules: [{ required: true, message: '请选择！' }] },
              ]">
                <a-select-option v-for="item in docMajorType" :key="item.value" :value="item.value">{{ item.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="资料名称">
              <a-input v-decorator="[
                'name',
                { rules: [{ required: true, message: '请输入！' }] },
              ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="12">
            <a-form-item>
              <span class="required" slot="label">附件</span>
              <a-space>
                <Upload :list="attachmentList" @change="change" />
                <a href="#" class="danger" v-if="attachmentList.length > 0" @click.prevent="remove">删除</a>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button htmlType="submit" type="primary" :loading="loading">保存</a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { fetchCategory, add } from "@/api/knowledge/think";
import { mapGetters } from "vuex";
import Upload from "./components/upload";

export default {
  name: "thinkAdd",

  components: {
    Upload,
  },

  data() {
    return {
      form: this.$form.createForm(this),
      categoryList: [],
      attachmentList: [],

      loading: false,

    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    docMajorType() {
      return this.findDataDict("docMajorType");
    },
  },

  activated() {
    fetchCategory().then((res) => {
      try {
        this.categoryList = Object.keys(res).map(key => {
          return {
            name: key,
            pid: res[key][0].id
          }
        })
      } catch (error) {
        console.error(error)
      }

    });
  },

  methods: {

    change(list) {
      this.attachmentList = list;
    },

    remove() {
      this.attachmentList = []
    },

    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {

          if (this.attachmentList.length === 0) {
            this.$message.error("请上传文件")
            return;
          }

          this.loading = true;

          add({
            ...values,
            attachmentList: this.attachmentList
          })
            .then(() => {
              this.$close(this.$route.path);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 30px 164px;
}

.center {
  margin-top: 91px;
}
</style>
